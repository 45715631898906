@mixin base($theme){
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    $main-blue: #1B2735;
    $main-dark: #090A0F;
    $background: #212121;
    $white: #ffffff;
    $main-light: #EEEEEE;

    html, body { height: 100%; margin: 0; }

    html {
        background: radial-gradient(ellipse at bottom, $main-blue 0%, $main-dark 100vh, $main-dark);
        background-repeat: no-repeat;
        background-color: #182029;
    }

    h1{
        color: $main-light;
        text-transform: uppercase;
    }
    h3, h4, h5, h6{
        color: $main-light;
    }

    p,li,a, svg {
        font-family: "Helvetica";
        font-size: 14px;
        color: $main-light;
    }
    ul {
        padding: 0;
        margin: 0;
    }
    button {
        background: transparent;
        border: none;
    }
    .logo {
        display: inline-flex;
        &_svg{
            fill: $main-light;
            width: 35%;
        }
    }

    .container {
        max-width: 1250px;
        position: relative;
        margin: 0 auto;
    }

    .content {
        background-color: $background;
        padding: 0 20px 0 20px;
    }
    .skewedBoxRight {
        background: $background;
        height: 0;
        &::before {
            content: "";
            height: 140px;
            top: -70px;
            bottom: 0;
            background: #212121;
            -webkit-transform: skew(0deg, -7deg);
            transform: skew(0deg, -3deg);
            display: block;
            position: relative;
            z-index: -1;
            box-shadow: 0px 0px 24px -8px #000;
        }
    }
    .skewedBoxLeft {
        background: $background;
        height: 0;
        &::before {
            content: "";
            height: 140px;
            top: -70px;
            bottom: 0;
            background: #212121;
            -webkit-transform: skew(0deg, 3deg);
            transform: skew(0deg, 3deg);
            display: block;
            position: relative;
            z-index: -1;
            box-shadow: 0px 0px 24px -8px #000;
        }
    }

}




