// Spacing helpers
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .margin-#{$side}--#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .padding-#{$side}--#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

// General utilities
.text {
    &--center {
        text-align: center;
    }
    &--left {
        text-align: left;
    }
    &--right {
        text-align: right;
    }
}

.clear {
    clear: both;
}

.hidden {
    visibility: hidden;
}

.caps {
    text-transform: uppercase;
}

.spacer {
  height: 130px;
}

.flex {
	display: flex;
	&-space-between{
		justify-content: space-between;
	}
	&-space-around{
		justify-content: space-around;
	}
	&-wrap{
		flex-wrap: wrap;
	}
}